import './App.css';
import { gsap, Power2, Power3, Power4 } from "gsap";
import { useEffect, useLayoutEffect, useRef } from 'react';

import ReactAudioPlayer from 'react-audio-player';

function App() {
	let heading1_ref = useRef();
	let heading2_ref = useRef();
	let logoRef = useRef();
	let consRef = useRef();
	let audio = useRef();
	let picRef = useRef([]);
	let imgObj = [];
	let start = true;

	let bgaudio = new Audio("bgaudio.mp3");
	const start3 = () => {
		console.log('cliced');
		bgaudio.autoplay = true;
		bgaudio.muted = false;
		bgaudio.loop = true;
		bgaudio.play()
	  }

	const colorArray = [
		"#0C99FF",
		"#FFFFFF"
	];
	const colorArray2 = [
		"#262626",
		"#D7D7D7",
	];
	let curColor = 1;

	useEffect(() => {
		for (let i = 0; i < picRef.current.length; i++) {
			imgObj[i] = picRef.current[i];
		}

		let boxWidth = window.innerWidth;
		let wrapWidth = (imgObj.length - 1) * boxWidth;
		let wrap = gsap.utils.wrap(-boxWidth, wrapWidth);
		let wrapPos = gsap.utils.wrap(["-=200", "+=200"]);

		// gsap.fromTo(imgObj[0], {
		// 	backgroundSize: '120% 120%'
		// }, {
		// 	duration: 3,
		// 	ease: Power4.easeOut,
		// 	backgroundSize: '100% 100%'
		// });
		gsap.fromTo(logoRef.current, {
			opacity: '0',
			y: '-=20'
		}, {
			duration: 3,
			ease: Power4.easeOut,
			opacity: '1',
			y: '+=20'
		});
		gsap.fromTo(consRef.current, {
			opacity: '0',
			y: '-=20'
		}, {
			duration: 3,
			ease: Power4.easeOut,
			opacity: '1',
			y: '+=20'
		});
		gsap.to(heading1_ref.current, {
			duration: 3,
			ease: Power4.easeOut,
			opacity: 1,
			x: "-=200",
		});

		gsap.set(imgObj, {
			x: function (i) {
				return i * boxWidth;
			}
		});
		function animate(delta) {
			gsap.to(imgObj, {
				duration: 3,
				ease: Power4.easeInOut,
				x: `-=${delta}`,
				modifiers: {
					x: function (x) {
						return wrap(parseFloat(x)) + "px";
					}
				}
			});

			// gsap.fromTo(imgObj[curColor%2], {
			// 	backgroundSize: '150% 150%'
			// }, {
			// 	duration: 3,
			// 	ease: Power4.easeInOut,
			// 	backgroundSize: '100% 100%'
			// });

			gsap.to(logoRef.current, {
				duration: 3,
				ease: Power4.easeInOut,
				fill: function () {
					return gsap.utils.wrap(colorArray, curColor++);
				},
			});

			gsap.to(consRef.current, {
				duration: 3,
				ease: Power4.easeInOut,
				color: function () {
					return gsap.utils.wrap(colorArray2, curColor);
				},
			});

			let tl = gsap.timeline();
			if (curColor % 2 != 0) {
				tl.to(heading1_ref.current, { x: "-=200", opacity: 0, ease: Power4.easeInOut, duration: 3 });
				tl.to(heading1_ref.current, { x: "+=400", opacity: 0, ease: Power4.easeInOut, duration: 0 });
			} else {
				tl.to(heading1_ref.current, { x: "-=200", opacity: 1, ease: Power4.easeInOut, duration: 2.95 });
			}

			let t2 = gsap.timeline();
			if (curColor % 2 != 0) {
				t2.to(heading2_ref.current, { x: "-=200", opacity: 1, ease: Power4.easeInOut, duration: 2.95 });
			} else {
				t2.to(heading2_ref.current, { x: "-=200", opacity: 0, ease: Power4.easeInOut, duration: 3 });
				t2.to(heading2_ref.current, { x: "+=400", opacity: 0, ease: Power4.easeInOut, duration: 0 });
			}


		}
		loop(boxWidth);

		function loop(boxWidth) {
			gsap.delayedCall(8, () => {
				animate(boxWidth);
				loop(boxWidth);
			});
		}
	}, []);

	return (
		<div className='relative' onClick={start3} ref={audio}>
			<div className='flex justify-between pr-5 pl-5  md:px-20 py-1 fixed w-screen z-20' >
				<svg ref={logoRef} className='py-1 md:py-2 w-[400px] h-[70px] md:mt-4' viewBox="0 0 360 60" fill="#0C99FF" xmlns="http://www.w3.org/2000/svg">
					<path d="M14.6829 57.8181C9.51491 57.8181 5.78367 56.5315 3.48917 53.9582C1.21612 51.3849 0.0795898 47.2892 0.0795898 41.6708V38.9979L11.2733 38.5429V43.2148C11.2733 44.5229 11.4663 45.5522 11.8523 46.3027C12.2597 47.0318 12.9567 47.3964 13.9431 47.3964C14.9724 47.3964 15.68 47.0962 16.066 46.4957C16.4735 45.8953 16.6772 44.9089 16.6772 43.5365C16.6772 41.7995 16.5056 40.3521 16.1625 39.1941C16.0802 38.911 15.983 38.6316 15.871 38.356L27.7161 37.8745C27.8193 38.7872 27.8709 39.7632 27.8709 40.8024C27.8709 46.1205 26.8845 50.2913 24.9116 53.3149C22.9602 56.317 19.5507 57.8181 14.6829 57.8181Z" />
					<path d="M21.943 26.6382H5.10596C1.75505 23.2149 0.0795898 19.3373 0.0795898 15.0053C0.0795898 10.1805 1.19467 6.50283 3.42484 3.97245C5.67645 1.44207 8.9252 0.17688 13.1711 0.17688C18.3605 0.17688 22.0382 1.56001 24.204 4.32628C26.3913 7.09254 27.4849 11.2956 27.4849 16.9353H15.9695V13.0432C15.9695 12.2712 15.7444 11.6708 15.294 11.2419C14.8652 10.8131 14.2755 10.5986 13.5249 10.5986C12.6243 10.5986 11.9595 10.8559 11.5306 11.3706C11.1232 11.8638 10.9195 12.5071 10.9195 13.3006C10.9195 14.094 11.1339 14.9517 11.5628 15.8738C11.9917 16.7959 12.8387 17.8574 14.1039 19.0582L20.6336 25.3306C21.0827 25.7576 21.5191 26.1935 21.943 26.6382Z" />
					<path d="M32.984 37.6603V57.3035H56.3686V46.2706H44.4994V37.1922L32.984 37.6603Z" />
					<path d="M55.1784 26.6382H32.984V0.691534H55.6288V11.6279H44.4994V22.6608H55.1784V26.6382Z" />
					<path d="M61.9165 36.4842L59.9055 57.3035H70.9063L71.6139 48.1684H78.4331L79.2372 57.3035H89.9485L87.8729 35.4291L77.2832 35.8596L77.5646 39.1297H72.4181L72.6488 36.048L61.9165 36.4842Z" />
					<path d="M73.3532 26.6382H62.8675L65.3737 0.691534H84.5768L87.0387 26.6382H76.4899L75.0878 10.3413H74.5732L73.3532 26.6382Z" />
					<path d="M102.196 34.8469V57.3035H117.378C122.074 57.3035 125.355 55.8453 127.221 52.9289C129.108 50.0125 130.051 45.8739 130.051 40.5129V33.7145L118.665 34.1774V44.2763C118.665 46.292 117.625 47.2999 115.544 47.2999H113.582V34.384L102.196 34.8469Z" />
					<path d="M113.582 26.6382H102.196V0.691534H117.764C121.838 0.691534 124.905 1.82806 126.963 4.10112C129.022 6.35273 130.051 9.65509 130.051 14.0082V26.6382H118.665V15.0697C118.665 13.1826 118.407 11.971 117.893 11.4349C117.399 10.8774 116.381 10.5986 114.837 10.5986H113.582V26.6382Z" />
					<path d="M136.762 33.4417L134.457 57.3035H145.457L146.165 48.1684H152.984L153.788 57.3035H164.5L162.138 32.4102L151.575 32.8396L152.116 39.1297H146.969L147.428 33.0082L136.762 33.4417Z" />
					<path d="M147.904 26.6382H137.419L139.925 0.691534H159.128L161.59 26.6382H151.041L149.639 10.3413H149.124L147.904 26.6382Z" />
					<path d="M168.841 32.1377V40.4485C168.841 52.0283 172.883 57.8181 180.967 57.8181C185.213 57.8181 188.323 56.0597 190.295 52.5429L192.225 57.3035H196.761V31.0028L182.929 31.565V34.1119H185.953V42.1855C185.953 43.7509 185.749 45.0161 185.342 45.9811C184.956 46.9246 184.162 47.3964 182.962 47.3964C181.804 47.3964 181.021 46.9246 180.613 45.9811C180.227 45.0161 180.034 43.7724 180.034 42.2498V31.6827L168.841 32.1377Z" />
					<path d="M180.034 26.6382H168.841V16.0347C168.841 5.4628 173.473 0.17688 182.736 0.17688C186.553 0.17688 189.481 0.948861 191.518 2.49282C193.555 4.01534 194.959 6.24551 195.731 9.18332C196.503 12.1211 196.889 15.7023 196.889 19.9267H185.824V14.9088C185.824 13.6865 185.653 12.668 185.31 11.8531C184.988 11.0168 184.302 10.5986 183.251 10.5986C181.943 10.5986 181.075 11.0382 180.646 11.9174C180.238 12.7966 180.034 13.7616 180.034 14.8123V26.6382Z" />
					<path d="M182.929 26.6382H196.761V24.3334H182.929V26.6382Z" />
					<path d="M202.131 30.7844V40.4485C202.131 52.0283 206.173 57.8181 214.258 57.8181C218.504 57.8181 221.613 56.0597 223.586 52.5429L225.516 57.3035H230.051V29.6495L216.22 30.2117V34.1119H219.243V42.1855C219.243 43.7509 219.04 45.0161 218.632 45.9811C218.246 46.9246 217.453 47.3964 216.252 47.3964C215.094 47.3964 214.311 46.9246 213.904 45.9811C213.518 45.0161 213.325 43.7724 213.325 42.2498V30.3294L202.131 30.7844Z" />
					<path d="M213.325 26.6382H202.131V16.0347C202.131 5.4628 206.763 0.17688 216.027 0.17688C219.844 0.17688 222.771 0.948861 224.808 2.49282C226.845 4.01534 228.25 6.24551 229.022 9.18332C229.794 12.1211 230.18 15.7023 230.18 19.9267H219.115V14.9088C219.115 13.6865 218.943 12.668 218.6 11.8531C218.278 11.0168 217.592 10.5986 216.541 10.5986C215.233 10.5986 214.365 11.0382 213.936 11.9174C213.529 12.7966 213.325 13.7616 213.325 14.8123V26.6382Z" />
					<path d="M216.22 26.6382H230.051V24.3334H216.22V26.6382Z" />
					<path d="M235.872 29.4129V57.3035H259.256V46.2706H247.387V33.3077H258.066V28.5107L235.872 29.4129Z" />
					<path d="M258.066 26.6382H235.872V0.691534H258.517V11.6279H247.387V22.6608H258.066V26.6382Z" />
					<path d="M264.337 28.2557V57.3035H275.788V31.4743C277.289 31.4743 278.372 31.678 279.037 32.0854C279.723 32.4714 280.066 33.49 280.066 35.1412V57.3035H291.228V35.881C291.228 33.6508 290.863 31.8388 290.134 30.445C289.427 29.0297 288.183 28.1397 286.403 27.7752C286.814 27.6411 287.199 27.4866 287.559 27.3118L264.337 28.2557Z" />
					<path fillRule="evenodd" clipRule="evenodd" d="M288.688 26.6382H264.337V0.691534H281.707C284.602 0.691534 286.789 1.3563 288.269 2.68582C289.748 3.9939 290.735 5.83807 291.228 8.21835C291.743 10.5772 292 13.3542 292 16.5493C292 19.6372 291.603 22.1033 290.81 23.9475C290.35 25.046 289.643 25.9429 288.688 26.6382ZM278.587 21.6637H275.853V11.1776H278.49C279.434 11.1776 280.077 11.5207 280.42 12.2069C280.763 12.8931 280.935 14.3406 280.935 16.5493C280.935 19.9589 280.152 21.6637 278.587 21.6637Z" />
				</svg>

				<div className='text-white flex flex-col items-end opacity-80' ref={consRef}>
					<h4 className="md:text-xl font-bold mt-3 leading-[15px]">Consultation</h4>
					<div className="text-xs md:text-sm leading-[15px] md:leading-4  flex flex-col items-end">
						<p>info@seadagger.com</p>
						<p>+8801967905601</p>
					</div>
				</div>
			</div>

			<div className=' md:mx-auto font-anton md:tracking-wide overflow-hidden relative'>
				<div className='h-screen w-screen bg-right-bottom md:bg-center bg-cover fixed top-0 left-0 bg-bg1sm md:bg-bg1md' ref={(el) => (picRef.current[0] = el)}
				>
					<div className="absolute md:w-2/3 right-0
						pr-5 pl-10 bottom-20
						 md:px-20 md:bottom-16 md:pr-20 text-right opacity-0 translate-x-[200px] " ref={heading1_ref}>
						<h1 className='text-5xl md:text-5xl text-[#FF7A00] md:leading-[55px]'>A Revolutionary Defense System for Merchant Vessels</h1>
						<h2 className='text-xl leading-[22px] mt-5 md:text-2xl md:my-1 text-white '>Sea Dagger is a Revolutionary Countermeasure against Maritime Piracy </h2>
					</div>
				</div>

				<div className='h-screen w-screen bg-center bg-cover fixed top-0 left-0 bg-bg2sm md:bg-bg2md' ref={(el) => (picRef.current[1] = el)}>
					<div className="h-2/3 md:h-screen md:w-1/2 absolute md:right-0 flex justify-center pl-5" >
						<div className="my-auto md:mx-14 opacity-0 translate-x-[200px] text-[#0F0F0F]" ref={heading2_ref}>
							<h1 className='text-4xl md:text-5xl md:mt-10 '>Sea Dagger Ensures <br /> Freedom of Navigation <br />by Defending your <br />Ship and Fleet</h1>
							<h2 className='text-sm md:text-2xl md:mt-5 w-2/3 mt-4'>The system pits pirates against overwhelming computing power and a state of the art automated defense mechanism.</h2>
						</div>
					</div>
				</div>

			</div>
		</div>
	);
}

export default App;
